import Vue from 'vue';

import BaseButton from '~/components/shared/Base/BaseButton';
import BaseInputText from '~/components/shared/Base/BaseInputText';
import BaseInputDropdown from '~/components/shared/Base/BaseInputDropdown';
import BaseInputRadio from '~/components/shared/Base/BaseInputRadio';
import BaseH1 from '~/components/shared/Base/BaseH1';
import BaseH2 from '~/components/shared/Base/BaseH2';
import BaseH3 from '~/components/shared/Base/BaseH3';
import BaseH4 from '~/components/shared/Base/BaseH4';
import BaseH5 from '~/components/shared/Base/BaseH5';
import BaseH6 from '~/components/shared/Base/BaseH6';

const components = {
	BaseButton,
	BaseInputText,
	BaseInputDropdown,
	BaseInputRadio,
	BaseH1,
	BaseH2,
	BaseH3,
	BaseH4,
	BaseH5,
	BaseH6,
};

Object.entries(components).forEach(([name, component]) => {
	Vue.component(name, component);
});
