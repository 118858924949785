export const LAYOUTS_BY_SOLUTION_AND_TEMPLATE = {
	// Plop: layout - add solution
	main: {
		// Plop: layout - add main layout
	},
};

export const getLayoutFromSolutionAndTemplate = (solution, template) =>
	LAYOUTS_BY_SOLUTION_AND_TEMPLATE[solution]?.[template] ??
	`${solution}/Default`;
