// Last modified: 2022/07/19 15:32:58
import { getComponentFromRoute } from '~/citi-baseline/assets/js/utilities/helpers';

export default (to, from) => {
	const fromComponent = getComponentFromRoute(from);
	const toComponent = getComponentFromRoute(to);
	const solution = (fromComponent || toComponent)?.$store?.getters?.solution;
	if (solution) {
		return `t-page-${solution}`;
	}

	return 't-page';
};
