<script>
export default {
	name: 'BaseButton',
	functional: true,

	props: {
		tag: {
			type: String,
			default: 'button',
		},
		type: {
			type: String,
			default: 'primary',
			validator(value) {
				return [
					'primary',
					'primary-light',
					'secondary',
					'extra',
					'white',
				].includes(value);
			},
		},
		outline: {
			type: String,
			default: 'none',
			validator(value) {
				return ['none', 'white-1', 'white-2'].includes(value);
			},
		},
		inGroup: {
			type: Boolean,
			default: true,
		},
	},

	render(h, { props, slots, data }) {
		const Tag = props.tag;
		const html = data?.domProps?.innerHTML;
		const text = data?.domProps?.textContent;
		const { default: slot, icon } = slots() || {};
		const hasContent = text || slot || html;

		html && delete data.domProps.innerHTML;
		text && delete data.domProps.textContent;

		/**
		 * Disables click-events if aria-disabled
		 * is set to true.
		 */
		if (
			data?.attrs &&
			typeof data.attrs['aria-disabled'] === 'string' &&
			data.attrs['aria-disabled'].toLowerCase() === 'true'
		) {
			!data?.on && (data.on = {});
			data.on.click = (event) => event.preventDefault();
		}

		const bindings = {
			...data,
			...data.attrs,

			class: [
				'group',
				'c-base-button',
				`c-base-button--type--${props.type}`,
				`c-base-button--hover--${
					hasContent ? 'space-between' : 'default'
				}`,
				{ 'c-base-button--simple': !hasContent },
				{ 'c-base-button--in-group': props.inGroup },
				{
					[`c-base-button--outline--${props.outline}`]:
						props.outline && props.outline !== 'none',
				},
				data.class,
			],
			staticClass: data.staticClass,
		};

		return (
			<Tag {...bindings}>
				{html && !text && (
					<span
						class="c-base-button__text"
						domPropsInnerHTML={html}
					></span>
				)}

				{(text || slot) && (
					<span class="c-base-button__text">{text || slot}</span>
				)}

				{icon && <span class="c-base-button__icon">{icon}</span>}
			</Tag>
		);
	},
};
</script>

<style lang="postcss">
/** Base */
.c-base-button {
	@apply appearance-none relative isolate;
	@apply flex items-center gap-x-8;
	@apply px-sm py-12 rounded-full;
	@apply font-semibold text-button uppercase text-white;
	@apply duration-500 ease-smooth-out;
	@apply bg-primary-button;

	&:before {
		content: '';
		z-index: -1;

		@apply absolute left-0 top-0 right-0 bottom-0;
		border-radius: inherit;
		transition: inherit;
	}
}

.c-base-button[aria-disabled='true'] {
	@apply opacity-10 cursor-default;
}

.c-base-button--simple {
	@apply px-10 py-10;
}

.c-base-button__icon svg {
	@apply h-12;
}

/** Types */
.c-base-button--type--secondary {
	@apply text-black px-0 py-0 gap-x-xs;
	@apply bg-transparent;
}
.c-base-button--type--primary-light {
	@apply bg-primary text-primary-button;
}
.c-base-button--type--extra {
	@apply gap-x-xs;
	@apply bg-extra;
}
.c-base-button--type--white {
	@apply bg-white text-primary-button;
}

.c-base-button--type--secondary .c-base-button__icon,
.c-base-button--type--extra .c-base-button__icon {
	@apply order-1;
}

.c-base-button--type--secondary .c-base-button__text,
.c-base-button--type--extra .c-base-button__text {
	@apply order-2 pr-8;
}

/** Outlines */
.c-base-button--outline--white-1:before {
	@apply border border-white;
	margin: -1px;
}
.c-base-button--outline--white-2:before {
	@apply border-2 border-white;
	margin: -2px;
}

/** Default hover */
.c-base-button--type--primary:hover:not([aria-disabled='true']),
.group:hover
	.c-base-button--type--primary.c-base-button--in-group:not([aria-disabled='true']) {
	&:hover:before {
		@apply bg-primary-button-hover;
	}
}

.c-base-button--type--primary-light:hover:not([aria-disabled='true']),
.group:hover
	.c-base-button--type--primary-light.c-base-button--in-group:not([aria-disabled='true']) {
	&:hover:before {
		@apply bg-primary-light;
	}
}

.c-base-button--hover--default.c-base-button--type--primary:hover:not([aria-disabled='true']),
.group:hover
	.c-base-button--hover--default.c-base-button--type--primary.c-base-button--in-group:not([aria-disabled='true']),
[data-hover='hover']
	.c-base-button--hover--default.c-base-button--type--primary.c-base-button--in-group:not([aria-disabled='true']) {
	@apply px-md;
}

.c-base-button--hover--default.c-base-button--type--primary.c-base-button--simple:hover:not([aria-disabled='true']),
.group:hover
	.c-base-button--hover--default.c-base-button--type--primary.c-base-button--simple.c-base-button--in-group:not([aria-disabled='true']),
[data-hover='hover']
	.c-base-button--hover--default.c-base-button--type--primary.c-base-button--simple.c-base-button--in-group:not([aria-disabled='true']) {
	@apply px-18;
}

.c-base-button--type--extra:hover:not([aria-disabled='true']),
.group:hover
	.c-base-button--type--extra.c-base-button--in-group:not([aria-disabled='true']) {
	&:hover:before {
		@apply opacity-80;
	}
}

/** Space between hover */
/*
	The icon and text are translated, and the button
	background (:before) is scaled.
*/
.c-base-button--hover--space-between:not([aria-disabled='true']) > * {
	@apply duration-500 ease-smooth-out;
}

.c-base-button--hover--space-between:not([aria-disabled='true']):hover,
.group:hover
	.c-base-button--hover--space-between.c-base-button--in-group:not([aria-disabled='true']) {
	&:before {
		@apply -left-4 -right-4;
	}
	& .c-base-button__text:not(:last-child) {
		@apply transform -translate-x-4;
	}
	& .c-base-button__icon:not(:first-child) {
		@apply transform translate-x-4;
	}
}

.c-base-button--hover--space-between.c-base-button--type--secondary:not([aria-disabled='true']):hover,
.group:hover
	.c-base-button--hover--space-between.c-base-button--type--secondary.c-base-button--in-group:not([aria-disabled='true']),
.c-base-button--hover--space-between.c-base-button--type--extra:not([aria-disabled='true']):hover,
.group:hover
	.c-base-button--hover--space-between.c-base-button--type--extra.c-base-button--in-group:not([aria-disabled='true']) {
	&:before {
		@apply -right-8;
	}
	& .c-base-button__text:not(:last-child) {
		@apply transform translate-x-8;
	}
	& .c-base-button__icon:not(:first-child) {
		@apply transform translate-x-0;
	}
}
</style>
