<template>
	<Component
		:is="doctype"
		v-if="doctype"
		v-bind="$data"
		@hook:mounted="triggerCludoScripts"
	/>
</template>

<script>
import fetchUmbracoData from '@limbo-works/umbraco-client/client';
import { mapGetters } from 'vuex';

import doctypes, {
	getDoctypeFromSolutionAndTemplate,
} from '~/assets/js/doctypes';
import key from '~/assets/js/page-key';
import transition from '~/citi-baseline/assets/js/page-transition.js';
import { getComponentFromRoute } from '~/citi-baseline/assets/js/utilities/helpers';

export default {
	name: 'WildcardPage',

	key: (route) => key(route),

	components: {
		...doctypes,
	},
	provide() {
		return {
			pageKey: this.$data.key,
		};
	},

	layout: ({
		store: {
			getters: { layout },
		},
	}) => layout,

	transition(to, from) {
		const fromComponent = getComponentFromRoute(from);
		const toComponent = getComponentFromRoute(to);
		const solution = (fromComponent || toComponent)?.$store?.getters
			?.solution;

		if (solution === 'main') {
			return {
				name: `t-page-${solution}`,
				duration: 500,

				beforeLeave() {
					document.body.classList.add('js-page-transition');
					document.body.classList.add('js-page-transition--leave');
				},
				beforeEnter() {
					document.body.classList.remove('js-page-transition--leave');
					document.body.classList.add('js-page-transition--enter');
				},
				afterEnter() {
					document.body.classList.remove('js-page-transition--enter');
					document.body.classList.remove('js-page-transition');
				},
			};
		}

		return transition;
	},

	async asyncData({
		req,
		$config,
		error,
		redirect,
		route,
		store: { commit },
	}) {
		let fallbackHost = '';
		if (process.server) {
			fallbackHost = req.headers.host;
		} else {
			const { host } = new URL(window.location.href);
			fallbackHost = host;
		}

		const data = await fetchUmbracoData({
			onResponse(response) {
				commit('Cookies/SET_COOKIE', response?.headers?.['set-cookie']);
				return response;
			},
			error,
			params: {
				...route.query,
				...(process.env.NODE_ENV === 'development'
					? { cache: false }
					: {}),
				parts: ['content'],
				appHost: $config.APP_HOST || fallbackHost,
			},
			redirect,
			route,
		});

		// NOTE: if data is falsy, a redirect has taken place, or an error has been handled
		if (!data) {
			return;
		}

		const { meta, content = {} } = data;

		if (
			content.template === 'ErrorPage' ||
			content.template === 'Page404' ||
			(meta?.code === 404 && content)
		) {
			commit('SET_ERROR_DATA', content);
			return;
		}

		commit('SET_TEMPLATE', content.template);
		commit('SET_PAGE_ID', content.id);

		return content;
	},

	data() {
		return {
			allowCludoScripts: false,
			cludoScriptProgress: 0,
		};
	},

	head() {
		/* const { site } = this.$store.state; */

		const {
			link = [],
			script = [],
			meta = [],
			htmlAttrs = {},
			__dangerouslyDisableSanitizersByTagID = {},
			__dangerouslyDisableSanitizers = [],
		} = this.meta || {};
		/* if (site && site.colorThemeSubsite) {
			htmlAttrs['theme'] = site.colorThemeSubsite.label;
		} */

		// Theme handling
		const theme = this.$store.state.site?.theme;
		htmlAttrs.theme = htmlAttrs?.theme || theme?.label || 'Colour-Theme-1';

		if (
			script.length &&
			!__dangerouslyDisableSanitizers.includes('script')
		) {
			__dangerouslyDisableSanitizers.push('script');
		}

		// Cludo - https://customer.cludo.com/assets/53/12952/documentation.html
		const siteId = this.$store.state.site?.id;
		const cludoEngineId =
			siteId === 1158 ? 12952 : this.$store.state.site?.cludoEngineId;
		const searchUrl = this.$store.state.site?.searchPage?.url;
		const hasCludoEngineId = cludoEngineId > 0 ? true : siteId === 1158;

		if (searchUrl) {
			link.push({
				hid: 'cludo-stylesheet',
				rel: 'stylesheet',
				// href: 'https://customer.cludo.com/assets/53/12952/cludo-search.min.css',
				href: 'https://customer.cludo.com/assets/53/12952/cludo-search-updated.min.css',
				type: 'text/css',
			});
			script.push(
				{
					skip: !this.allowCludoScripts,
					body: true,
					hid: 'cludo-search-script-begin',
					src: 'https://customer.cludo.com/scripts/bundles/search-script.js',
					type: 'text/javascript',
					callback: () => {
						this.cludoScriptProgress++;
					},
				},
				hasCludoEngineId && {
					skip: !this.allowCludoScripts,
					body: true,
					hid: 'cludo-search-script-config',
					innerHTML: `
					var cludo_engineId = ${cludoEngineId};
					var cludo_searchUrl = '${searchUrl}';
					var cludo_filterValue = '${typeof window !== 'undefined' && location.origin}/';
					`,
					type: 'text/javascript',
				},
				hasCludoEngineId && {
					// for some reason the search page only works when delayed
					skip:
						!this.allowCludoScripts || this.cludoScriptProgress < 1,
					body: true,
					hid: 'cludo-search-script-end',
					src: `https://customer.cludo.com/assets/53/12952/${
						siteId === 1158 ? 'cludo-search' : 'cludo-helper'
					}.js`,
					type: 'text/javascript',
				}
			);
			__dangerouslyDisableSanitizersByTagID[
				'cludo-search-script-config'
			] = ['innerHTML'];
		}

		meta.push({
			hid: 'format-detection',
			name: 'format-detection',
			content: 'telephone=no',
		});

		return Object.assign(this.meta || {}, {
			htmlAttrs,
			link,
			script,
			meta,
			__dangerouslyDisableSanitizersByTagID,
			__dangerouslyDisableSanitizers,
		});
	},

	computed: {
		...mapGetters(['solution']),

		doctype() {
			const doctype = getDoctypeFromSolutionAndTemplate(
				this.solution,
				this.template
			);
			if (Object.keys(doctypes).includes(doctype)) {
				return doctype;
			}
			return null;
		},
	},

	beforeDestroy() {
		if (!this.$route.hash) {
			const { scrollX, scrollY } = window;
			const bodyTabIndex = document.body.hasAttribute('tabindex')
				? document.body.tabIndex
				: null;
			document.body.tabIndex = -1;
			document.body.focus();
			if (bodyTabIndex !== null) {
				document.body.tabIndex = bodyTabIndex;
			} else {
				document.body.removeAttribute('tabindex');
			}
			window.scrollTo(scrollX, scrollY);
		}
	},

	methods: {
		triggerCludoScripts() {
			this.allowCludoScripts = true;
		},
	},
};
</script>
<style>
/*! purgecss start ignore */
@media print {
	#Coi-Renew {
		display: none !important;
	}
}

@screen print {
	@page {
		margin-top: 2.97cm;
		margin-bottom: 2.97cm;
	}
}
</style>
