<template>
	<div :id="wrapperId" class="c-base-input-text relative">
		<label v-if="label" v-text="`${required ? '*' : ''}${label}`"></label>
		<Component
			:is="tag"
			v-if="tag"
			:id="computedId"
			:value="value"
			v-bind="properties"
			:class="[
				'w-full h-56 bg-white px-24 text-text-placeholder',
				'overflow-hidden rounded-full',
				tall ? 'h-70' : '',
			]"
			:aria-label="!label && placeholder"
			v-on="{ ...$listeners, input: updateValue }"
		/>

		<slot name="button"></slot>
	</div>
</template>

<script>
const tagMap = {
	textarea: 'textarea',
};
export default {
	name: 'BaseInputText',
	inheritAttrs: false,

	props: {
		id: String,
		wrapperId: {
			type: String,
			default: null,
		},
		type: {
			type: String,
			default: 'text',
		},
		name: {
			type: String,
			default: null,
		},
		label: {
			type: String,
			default: null,
		},
		required: {
			type: Boolean,
			default: false,
		},
		placeholder: {
			type: String,
			default: null,
		},
		autocomplete: {
			type: String,
			default: null,
		},
		value: {
			type: String,
			default: '',
		},
		tall: {
			type: Boolean,
			default: false,
		},
	},

	computed: {
		tag() {
			if (!this.type) {
				return null;
			}
			return tagMap[this.type.toLowerCase()] || 'input';
		},

		computedId() {
			return this.id || `input_${this.type || 'unknown'}_${this._uid}`;
		},

		properties() {
			const { type, name, required, placeholder, autocomplete } = this;
			switch (this.tag) {
				case 'input':
					return { type, name, required, placeholder, autocomplete };
				case 'textarea':
					return { name, required, placeholder, autocomplete };
			}
			return null;
		},
	},

	methods: {
		updateValue(event) {
			this.$emit('input', event?.target?.value);
		},
	},
};
</script>
