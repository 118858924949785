<template>
	<div
		class="c-base-input-dropdown relative"
		@keydown.down.prevent.capture="arrow('down')"
		@keydown.up.prevent.capture="arrow('up')"
		@keydown.esc.capture="toggleActive(false)"
		@keydown.tab.capture="toggleActive(false)"
		@blur.capture="blur(true)"
		@focus.capture="blur(false)"
	>
		<div class="relative min-w-max h-full text-text z-10">
			<!-- eslint-disable -->
			<button
				:id="id"
				ref="toggle"
				:aria-controls="`c-dropdown__list-0${_uid}`"
				role="combobox"
				:aria-expanded="String(states.isActive)"
				:aria-owns="`c-dropdown__list-0${_uid}`"
				aria-haspopup="listbox"
				:aria-label="a11yLabel"
				:aria-activedescendant="
					states.hoverIndex > -1 &&
					this.$refs.options[states.hoverIndex]
						? this.$refs.options[states.hoverIndex].id
						: null
				"
				type="button"
				:class="[
					'w-full h-full min-h-56 bg-white px-24 py-4',
					'overflow-hidden',
					{ 'rounded-full': !bookMayor },
					{
						'rounded-md border-2 border-primary-button bg-primary-light':
							bookMayor,
					},
				]"
				@click.prevent="toggleActive()"
			>
				<!-- eslint-enable -->
				<div class="relative flex justify-between items-center">
					<span
						class="font-semibold"
						:title="
							selected && selected.label
								? selected.label
								: ariaLabel
						"
						v-text="
							selected && selected.label
								? selected.label
								: ariaLabel
						"
					>
					</span>
					<SvgCaret
						:class="[
							'w-12 flex-shrink-0 relative transform rotate-90',
							'duration-200 ease-smooth-out text-text',
							{ '-rotate-90': states.isActive },
						]"
					/>
				</div>
			</button>
		</div>
		<TransitionExt name="base-input-dropdown__list">
			<ul
				v-show="states.isActive"
				:id="`c-dropdown__list-0${_uid}`"
				:aria-label="a11yLabel"
				role="listbox"
				:class="[
					'c-base-input-dropdown__list',
					'absolute top-full left-0 mt-xs z-20',
					'bg-white text-text rounded-27',
					'w-full overflow-y-auto overflow-x-hidden shadow-md',
				]"
			>
				<!-- eslint-disable -->
				<li
					v-for="(o, index) in config.value"
					:key="o.label"
					ref="options"
					:data-value="o.value"
					:aria-selected="
						String(o.value === (selected && selected.value))
					"
					:selected="
						o.value === (selected && selected.value) ? true : null
					"
					role="option"
					tabindex="-1"
					:id="`option-${_uid}-0${index}`"
					:class="[
						'flex items-center px-24 cursor-pointer',
						'h-56 hover:bg-primary-light',

						{
							'font-semibold':
								o.value === (selected && selected.value),
						},
					]"
					@click="updateValue(o.value, true)"
					@keyup.enter="updateValue(o.value, true)"
				>
					<span>
						<!-- eslint-enable -->
						<slot :item="o">
							<span
								class="w-full whitespace-no-wrap truncate"
								:title="o.label"
								v-text="o.label"
							></span>
						</slot>
					</span>
				</li>
			</ul>
		</TransitionExt>
	</div>
</template>

<script>
/**
 * Dropdown.vue
 * This entire component is built to provide a custom SIMPLE single selection
 * select alternative.
 *
 * Structure and functionality is based on these docs:
 *  - https://www.w3.org/TR/wai-aria-practices-1.1/examples/combobox/aria1.1pattern/listbox-combo.html
 */
import SvgCaret from '~/assets/svgs/caret-icon.svg?inline';

export default {
	name: 'BaseInputDropdown',
	components: { SvgCaret },

	props: {
		id: String,
		ariaLabel: String,
		value: {
			type: String,
			default: '',
		},
		config: Object,
		rounded: {
			type: Boolean,
			default: true,
		},
		bookMayor: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		const selected = this.initialSelection(this.config, this.value);

		return {
			string: selected?.value ?? null,
			selected,
			a11yLabel: this.ariaLabel || (selected?.label ?? ''),
			states: {
				isActive: false,
				hoverIndex: -1,
				pendingBlur: null,
			},
		};
	},
	watch: {
		value: {
			handler() {
				this.refreshValueFromBinding();
			},
		},
		config: {
			deep: true,
			handler() {
				this.refreshValueFromBinding();
			},
		},
		string: {
			handler(val) {
				this.selected = this.config.value?.find?.(
					(item) => item.value === val
				);
			},
			immediate: true,
		},
		'states.hoverIndex': {
			handler(val) {
				if (val > -1) {
					this.$nextTick(() => {
						this.$refs.options[val].focus();
					});
				}
			},
		},
	},
	methods: {
		blur(bool) {
			if (bool) {
				this.states.pendingBlur = setTimeout(() => {
					this.toggleActive(false);
				}, 60);
				return;
			}
			clearTimeout(this.states.pendingBlur);
		},
		initialSelection(base, string) {
			// Set selected option based on provided value if provided value is "true"
			if (string) {
				return base.value?.find?.((item) => item.value === string);
			}
			// Set selected option that have been preselected from backend
			const preSelected = base.value?.find?.((item) => item.checked);
			if (preSelected) {
				return preSelected;
			}

			return null;
		},
		toggleActive(bool) {
			this.states.isActive =
				bool === undefined ? !this.states.isActive : bool;
			if (!this.states.isActive) {
				this.states.hoverIndex = -1;
			}
		},
		setHoverIndex(val) {
			if (val < 0) {
				this.states.hoverIndex = this.config.value.length - 1;
				return null;
			}
			if (val >= this.config.value.length) {
				this.states.hoverIndex = 0;
				return null;
			}
			this.states.hoverIndex = val;
			return null;
		},
		arrow(pressed) {
			if (pressed === 'down') {
				this.toggleActive(true);
				this.setHoverIndex(this.states.hoverIndex + 1);
			}
			if (pressed === 'up') {
				this.setHoverIndex(this.states.hoverIndex - 1);
			}
		},
		action(type) {
			this.$emit(type, this.string);
		},
		updateValue(value, focus) {
			if (focus) {
				this.$refs.toggle.focus();
			}

			if (value != null) {
				this.states.isActive = false;
				this.string = value;
				this.$emit('input', value);
			}
		},
		refreshValueFromBinding() {
			const newSelected = this.initialSelection(this.config, this.value);
			if (this.selected !== newSelected) {
				this.selected = newSelected;
			}
		},
	},
};
</script>

<style lang="postcss">
.c-base-input-dropdown ul {
	max-height: 320px;
	overflow-y: scroll;
}

.t-base-input-dropdown__list-enter-active,
.t-base-input-dropdown__list-leave-active {
	@apply duration-200 ease-smooth-out transform;
}

.t-base-input-dropdown__list-enter,
.t-base-input-dropdown__list-leave-to {
	@apply opacity-0;
}
</style>
