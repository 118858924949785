// Last modified: 2022/07/19 15:32:06
/**
 * Method to extract the page component used for the route if it exists.
 * @param {Route Object} route
 * @returns component object or undefined
 */
export function getComponentFromRoute(route) {
	return route?.matched?.[0]?.instances?.default || undefined;
}

// And here we export all the helpers as well
export default { getComponentFromRoute };
