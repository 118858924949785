<template>
	<Component
		:is="errorPage"
		v-if="errorPage"
		class="l-error"
		v-bind="{ error, ...(content === null ? {} : content) }"
	/>

	<article v-else class="l-error">
		<header class=">=1024:w-6col my-xl">
			<div class="text-h8" v-text="error.statusCode"></div>
			<BaseH1 class="mt-sm" v-text="error.message" />

			<NuxtLinkExt class="block mt-md" to="/">
				<BaseButton tag="div" class="w-fit" v-text="'Gå til forside'" />
			</NuxtLinkExt>
		</header>

		<div v-if="isDevelopment">
			<p>
				Note: this is a placeholder, please add an error page via `yarn
				new` prior to going live
			</p>
		</div>
	</article>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

import key from '~/assets/js/page-key';

const ERROR_PAGES_BY_SOLUTION = {
	// Plop: error page - add solution
	main: () =>
		import(
			/* webpackChunkName: "main__errorPage" */ '~/doctypes/main/ErrorPage.vue'
		),
	subsite: () =>
		import(
			/* webpackChunkName: "subsite__errorPage" */ '~/doctypes/subsite/ErrorPage.vue'
		),
};

export default {
	name: 'ErrorLayout',

	key: (route) => key(route),

	layout: ({
		store: {
			getters: { layout },
		},
	}) => layout,

	props: {
		error: {
			type: Object,

			default: () => ({
				statusCode: 500,
				message: 'Oops, something went wrong.',
			}),
		},
	},

	data() {
		return {
			isDevelopment: process.env.NODE_ENV === 'development',
		};
	},

	head() {
		/* const { site } = this.$store.state; */

		const {
			link = [],
			script = [],
			htmlAttrs = {},
			__dangerouslyDisableSanitizersByTagID = {},
			__dangerouslyDisableSanitizers = [],
		} = this.meta || {};
		/* if (site && site.colorThemeSubsite) {
			htmlAttrs['theme'] = site.colorThemeSubsite.label;
		} */

		// Theme handling
		const theme = this.$store.state.site?.theme;
		htmlAttrs.theme = htmlAttrs?.theme || theme?.label || 'Colour-Theme-1';

		if (
			script.length &&
			!__dangerouslyDisableSanitizers.includes('script')
		) {
			__dangerouslyDisableSanitizers.push('script');
		}

		// Cludo - https://customer.cludo.com/assets/53/12952/documentation.html
		// const siteId = this.$store.state.site?.id;
		// const searchUrl = this.$store.state.site?.searchPage?.url;
		// if (searchUrl && siteId === 1158) {
		// 	link.push({
		// 		hid: 'cludo-stylesheet',
		// 		rel: 'stylesheet',
		// 		href: 'https://customer.cludo.com/assets/53/12952/cludo-search.min.css',
		// 		type: 'text/css',
		// 	});
		// 	script.push(
		// 		{
		// 			skip: !this.allowCludoScripts,
		// 			body: true,
		// 			hid: 'cludo-search-script-begin',
		// 			src: 'https://customer.cludo.com/scripts/bundles/search-script.js',
		// 			type: 'text/javascript',
		// 			callback: () => {
		// 				this.cludoScriptProgress++;
		// 			},
		// 		},
		// 		{
		// 			skip: !this.allowCludoScripts,
		// 			body: true,
		// 			hid: 'cludo-search-script-config',
		// 			innerHTML: `
		// 			var cludo_engineId = 12952;
		// 			var cludo_searchUrl = '${searchUrl}';
		// 			`,
		// 			type: 'text/javascript',
		// 		},
		// 		{
		// 			// for some reason the search page only works when delayed
		// 			skip:
		// 				!this.allowCludoScripts || this.cludoScriptProgress < 1,
		// 			body: true,
		// 			hid: 'cludo-search-script-end',
		// 			src: 'https://customer.cludo.com/assets/53/12952/cludo-search.js',
		// 			type: 'text/javascript',
		// 		}
		// 	);
		// 	__dangerouslyDisableSanitizersByTagID[
		// 		'cludo-search-script-config'
		// 	] = ['innerHTML'];
		// }

		return Object.assign(this.meta || {}, {
			htmlAttrs,
			link,
			script,
			__dangerouslyDisableSanitizersByTagID,
			__dangerouslyDisableSanitizers,
		});
	},

	computed: {
		...mapGetters(['solution']),
		...mapState({ content: 'error' }),

		errorPage() {
			return ERROR_PAGES_BY_SOLUTION[this.solution];
		},

		title() {
			return `${this.error.statusCode} ${this.error.message}`;
		},
	},

	beforeDestroy() {
		this.$store.commit('SET_ERROR_DATA', null);
	},
};
</script>
