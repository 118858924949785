// Last modified: 2023/01/25 08:32:21
import Vue from 'vue';
const { visualViewport } = typeof window === 'undefined' ? {} : window;

const viewportData = Vue.observable({
	width: 0,
	height: 0,
	offsetTop: 0,
	offsetLeft: 0,
});

if (visualViewport) {
	let pendingUpdate = false;
	const viewportHandler = () => {
		if (pendingUpdate) {
			return;
		}
		pendingUpdate = true;

		requestAnimationFrame(() => {
			pendingUpdate = false;

			const { width, height, offsetTop, offsetLeft } = visualViewport;
			const { documentElement } = document;

			viewportData.width = width;
			viewportData.height = height;
			viewportData.offsetTop = offsetTop;
			viewportData.offsetLeft = offsetLeft;

			documentElement.style.setProperty(
				'--visual-viewport-width',
				`${width}px`
			);
			documentElement.style.setProperty(
				'--visual-viewport-height',
				`${height}px`
			);
			documentElement.style.setProperty(
				'--visual-viewport-offset-top',
				`${offsetTop}px`
			);
			documentElement.style.setProperty(
				'--visual-viewport-offset-left',
				`${offsetLeft}px`
			);
		});
	};

	viewportHandler();
	visualViewport.addEventListener('scroll', viewportHandler);
	visualViewport.addEventListener('resize', viewportHandler);
}

export { viewportData };
