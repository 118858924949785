var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"c-base-input-dropdown relative",on:{"!keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"down",40,$event.key,["Down","ArrowDown"])){ return null; }$event.preventDefault();return _vm.arrow('down')},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"up",38,$event.key,["Up","ArrowUp"])){ return null; }$event.preventDefault();return _vm.arrow('up')},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.toggleActive(false)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"tab",9,$event.key,"Tab")){ return null; }return _vm.toggleActive(false)}],"!blur":function($event){return _vm.blur(true)},"!focus":function($event){return _vm.blur(false)}}},[_c('div',{staticClass:"relative min-w-max h-full text-text z-10"},[_c('button',{ref:"toggle",class:[
				'w-full h-full min-h-56 bg-white px-24 py-4',
				'overflow-hidden',
				{ 'rounded-full': !_vm.bookMayor },
				{
					'rounded-md border-2 border-primary-button bg-primary-light':
						_vm.bookMayor,
				} ],attrs:{"id":_vm.id,"aria-controls":("c-dropdown__list-0" + _vm._uid),"role":"combobox","aria-expanded":String(_vm.states.isActive),"aria-owns":("c-dropdown__list-0" + _vm._uid),"aria-haspopup":"listbox","aria-label":_vm.a11yLabel,"aria-activedescendant":_vm.states.hoverIndex > -1 &&
				this.$refs.options[_vm.states.hoverIndex]
					? this.$refs.options[_vm.states.hoverIndex].id
					: null,"type":"button"},on:{"click":function($event){$event.preventDefault();return _vm.toggleActive()}}},[_c('div',{staticClass:"relative flex justify-between items-center"},[_c('span',{staticClass:"font-semibold",attrs:{"title":_vm.selected && _vm.selected.label
							? _vm.selected.label
							: _vm.ariaLabel},domProps:{"textContent":_vm._s(
						_vm.selected && _vm.selected.label
							? _vm.selected.label
							: _vm.ariaLabel
					)}}),_vm._v(" "),_c('SvgCaret',{class:[
						'w-12 flex-shrink-0 relative transform rotate-90',
						'duration-200 ease-smooth-out text-text',
						{ '-rotate-90': _vm.states.isActive } ]})],1)])]),_vm._v(" "),_c('TransitionExt',{attrs:{"name":"base-input-dropdown__list"}},[_c('ul',{directives:[{name:"show",rawName:"v-show",value:(_vm.states.isActive),expression:"states.isActive"}],class:[
				'c-base-input-dropdown__list',
				'absolute top-full left-0 mt-xs z-20',
				'bg-white text-text rounded-27',
				'w-full overflow-y-auto overflow-x-hidden shadow-md' ],attrs:{"id":("c-dropdown__list-0" + _vm._uid),"aria-label":_vm.a11yLabel,"role":"listbox"}},_vm._l((_vm.config.value),function(o,index){return _c('li',{key:o.label,ref:"options",refInFor:true,class:[
					'flex items-center px-24 cursor-pointer',
					'h-56 hover:bg-primary-light',

					{
						'font-semibold':
							o.value === (_vm.selected && _vm.selected.value),
					} ],attrs:{"data-value":o.value,"aria-selected":String(o.value === (_vm.selected && _vm.selected.value)),"selected":o.value === (_vm.selected && _vm.selected.value) ? true : null,"role":"option","tabindex":"-1","id":("option-" + _vm._uid + "-0" + index)},on:{"click":function($event){return _vm.updateValue(o.value, true)},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.updateValue(o.value, true)}}},[_c('span',[_vm._t("default",function(){return [_c('span',{staticClass:"w-full whitespace-no-wrap truncate",attrs:{"title":o.label},domProps:{"textContent":_vm._s(o.label)}})]},{"item":o})],2)])}),0)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }