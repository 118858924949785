var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"c-base-input-radio"},[_c('div',{class:[
			'flex h-full',

			{
				'items-center flex-wrap space-x-sm': _vm.layout === 'row',
				'flex-col items-start space-y-sm': _vm.layout !== 'row',
			} ]},[_vm._l((_vm.config.value),function(radio,index){return [_c('div',{key:("id-" + _vm._uid + "-0" + index),staticClass:"c-base-input-radio__item"},[_c('div',{staticClass:"flex items-center w-full h-full"},[_c('input',{class:[
							'overflow-hidden',
							'relative appearance-none cursor-pointer',
							'w-16 h-16 rounded-full border-2' ],attrs:{"id":("id-" + _vm._uid + "-0" + index),"type":"radio","name":_vm.config.name},domProps:{"value":radio.value,"checked":radio.value === _vm.selected.value
								? 'checked'
								: null},on:{"change":function($event){return _vm.updateValue($event.target.value)}}}),_vm._v(" "),_c('label',{class:[
							'ml-8 cursor-pointer whitespace-no-wrap',
							'font-semibold text-text' ],attrs:{"for":("id-" + _vm._uid + "-0" + index)},domProps:{"textContent":_vm._s(radio.label)},on:{"click":function($event){return _vm.action('change', radio.value)}}})])])]})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }