const state = () => ({
	setCookie: [],
});

const mutations = {
	SET_COOKIE(state, payload) {
		state.setCookie.length = 0;
		if (Array.isArray(payload)) {
			payload.forEach((item) => state.setCookie.push(item));
		} else {
			state.setCookie.push(payload);
		}
	},
};

export { state, mutations };
