import fetchUmbracoData from '@limbo-works/umbraco-client/client';

import { getDoctypeFromSolutionAndTemplate } from '~/assets/js/doctypes';
import { getLayoutFromSolutionAndTemplate } from '~/assets/js/layouts';

const DEFAULT_SOLUTION_NAME = 'main';

export const state = () => ({
	pageId: null,
	template: null,
	error: null,
	site: null,
});

export const actions = {
	async nuxtServerInit(
		_,
		{ req, $config, error, redirect, route, store: { commit } }
	) {
		const data = await fetchUmbracoData({
			onResponse(response) {
				commit('Cookies/SET_COOKIE', response?.headers?.['set-cookie']);
				return response;
			},
			error,
			params: {
				...route.query,
				...(process.env.NODE_ENV === 'development'
					? { cache: false }
					: {}),
				parts: 'site',
				appHost: $config.APP_HOST || req.headers.host,
			},
			redirect,
			route,
		});

		// NOTE: if data is falsy, a redirect has taken place, or an error has been handled
		if (!data) {
			return;
		}

		const { meta, site = {}, content = {} } = data;
		const template = content.template || site.pageTemplateAlias;

		// Handle error pages
		if (
			template === 'ErrorPage' ||
			template === 'Page404' ||
			(meta?.code === 404 && content)
		) {
			const { content: errorContent } = await fetchUmbracoData({
				onResponse(response) {
					commit(
						'Cookies/SET_COOKIE',
						response?.headers?.['set-cookie']
					);
					return response;
				},
				error,
				params: {
					...route.query,
					...(process.env.NODE_ENV === 'development'
						? { cache: false }
						: {}),
					parts: ['content'],
					appHost: $config.APP_HOST || req.headers.host,
				},
				redirect,
				route,
			});
			commit('SET_ERROR_DATA', errorContent);
		}

		commit('SET_SITE_DATA', site);
		commit('SET_TEMPLATE', template);
	},
};

export const mutations = {
	SET_PAGE_ID(state, id) {
		state.pageId = id;
	},

	SET_TEMPLATE(state, template) {
		state.template = template;
	},

	SET_ERROR_DATA(state, error) {
		state.error = error;
	},

	SET_SITE_DATA(state, site) {
		if (!site) {
			return;
		}

		state.site = site;
	},
};

export const getters = {
	solution: ({ site }) => site?.alias ?? DEFAULT_SOLUTION_NAME,

	layout: ({ template }, { solution }) =>
		getLayoutFromSolutionAndTemplate(solution, template),
	doctype: ({ template }, { solution }) =>
		getDoctypeFromSolutionAndTemplate(solution, template),
};
