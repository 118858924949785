// Requires https://www.npmjs.com/package/cookie-universal-nuxt with the configured alias "cookie"
export default function ({ $cookie, store }) {
	// Set the cookies from the store
	const { nodeCookie } = $cookie;
	let { setCookie } = store.state.Cookies || { setCookie: [] };
	setCookie = setCookie.filter((item) => item);
	if (setCookie.length) {
		const cookies = setCookie.map((str) => {
			const parts = str.split(';');
			const mainPart = parts.shift();
			const [name, value] = mainPart.split('=');
			const opts = nodeCookie.parse(parts.join(';')) || {};
			if (opts.expires) {
				opts.expires = new Date(opts.expires);
			}
			if (opts.maxAge) {
				opts.maxAge = +opts.maxAge;
			}
			if (opts.httpOnly) {
				opts.httpOnly = !!opts.httpOnly;
			}
			return { name, value, opts };
		});
		$cookie.setAll(cookies);
	}
}
