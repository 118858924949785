<template>
	<div class="c-base-input-radio">
		<div
			:class="[
				'flex h-full',

				{
					'items-center flex-wrap space-x-sm': layout === 'row',
					'flex-col items-start space-y-sm': layout !== 'row',
				},
			]"
		>
			<template v-for="(radio, index) in config.value">
				<div
					:key="`id-${_uid}-0${index}`"
					class="c-base-input-radio__item"
				>
					<div class="flex items-center w-full h-full">
						<input
							:id="`id-${_uid}-0${index}`"
							:value="radio.value"
							type="radio"
							:name="config.name"
							:checked="
								radio.value === selected.value
									? 'checked'
									: null
							"
							:class="[
								'overflow-hidden',
								'relative appearance-none cursor-pointer',
								'w-16 h-16 rounded-full border-2',
							]"
							@change="updateValue($event.target.value)"
						/>

						<label
							:class="[
								'ml-8 cursor-pointer whitespace-no-wrap',
								'font-semibold text-text',
							]"
							:for="`id-${_uid}-0${index}`"
							@click="action('change', radio.value)"
							v-text="radio.label"
						></label>
					</div>
				</div>
			</template>
		</div>
	</div>
</template>

<script>
export default {
	name: 'BaseInputRadio',
	props: {
		ariaLabel: {
			type: String,
			required: false,
		},
		value: {
			type: String,
			default: '',
		},
		config: {
			type: Object,
			required: false,
		},
		layout: {
			type: String,
			default: 'row',
		},
	},
	data() {
		const selected = this.initialSelection(this.config, this.value);
		return {
			selected,
			a11yLabel: this.ariaLabel || (selected?.label ?? ''),
			string: this.value,
		};
	},
	watch: {
		value: {
			handler() {
				this.refreshValueFromBinding();
			},
		},
		config: {
			deep: true,
			handler() {
				this.refreshValueFromBinding();
			},
		},
		string: {
			handler(val) {
				this.selected = this.config.value.find(
					(item) => item.value === val
				);
			},
			immediate: true,
		},
	},
	methods: {
		action(type) {
			this.$emit(type, this.string);
		},
		updateValue(value) {
			this.string = value;
			this.$emit('input', value);
		},
		initialSelection(base, string) {
			// Set selected option based on provided value if provided value is "true"
			if (string) {
				return base.value.find((item) => item.value === string);
			}
			// Set selected option that have been preselected from backend
			const preSelected = base.value.find((item) => item.checked);
			if (preSelected) {
				return preSelected;
			}
			// Find option with empty string as value or take the first option as a last measure
			return (
				base.value.find((item) => item.value === string) ||
				base.value[0]
			);
		},
		refreshValueFromBinding() {
			const newSelected = this.initialSelection(this.config, this.value);
			if (this.selected !== newSelected) {
				this.selected = newSelected;
			}
		},
	},
};
</script>

<style lang="postcss">
.c-base-input-radio__item input,
.c-base-input-radio__item input::before {
	@apply duration-300 ease-smooth-out;
}

.c-base-input-radio__item input {
	@apply border-primary-button;
}

.c-base-input-radio__item input::before {
	content: '';

	@apply absolute top-1/2 left-1/2;
	@apply transform -translate-x-1/2;
	@apply w-8 h-8 rounded-full bg-white;
	@apply opacity-0;
}

.c-base-input-radio__item input:checked::before {
	@apply -translate-y-1/2 opacity-100 bg-primary-button;
}

.c-base-input-radio__item input:checked {
	@apply border-primary-button;
}
</style>
